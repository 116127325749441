








import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    public date: number = 0;

    public created() {
        this.date = new Date().getFullYear();
    }
}


