











export default {
    name: "FirstLoading",
};
