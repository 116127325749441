




export default {
    name: "PortfolioDetailContent",
    props: ["html"]

};
